<template>
    <v-tooltip v-if="forDialog === false" bottom>
        <template v-slot:activator="{ on }">
            <v-btn icon text :loading="loading" @click="fetchQueryFiles()" v-on="on">
                <v-icon>mdi-folder-open-outline</v-icon>
            </v-btn>
        </template>
        <span>Browse query result files</span>
    </v-tooltip>
    <v-btn color="secondary" text v-else @click="fetchQueryFiles()">Browse query result files</v-btn>
</template>

<script>
export default {
    name: 'QueryGetFilesButton',
    props: {
        qid: String,
        forDialog: Boolean
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        fetchQueryFiles: function() {
            if (this.$props.forDialog) {
                this.$emit('closeDialog', { value: true })
            }
            this.$data.loading = true
            this.$store.dispatch('snapshotStore/fetchFullQueryData', this.$props.qid).finally(() => {
                this.$data.loading = false
                if (this.$store.state.snapshotStore.fullQueryData) {
                    this.$emit('queryFilesSuccess', { value: true })
                }
            })
        }
    }
}
</script>
